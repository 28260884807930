import React from "react"
import { Link, graphql } from "gatsby"

import Swiper from "../components/swiper"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { RichText } from "prismic-reactjs"
import Speakerlist from "../components/speakerlist"
import Program from "../components/program"

const RueckblickHeader = styled.h1`
padding-top: 1rem;
text-align: center;
`


const SwiperContainer = styled.div`
padding-top: 0rem;
max-width: 1200px; 
margin: 6rem auto 0 auto;
`

const StyledSwiper = styled(Swiper)`
padding-bottom: 3rem;
`

const FotoLinkContainer = styled.div`
display: flex;
  justify-content: center;
`
const FotoLink = styled(Link)`
  color: white !important;
  box-sizing: border-box;
  margin-top: 6rem;
  margin-left: 1rem;
  display: inline-block;
  text-decoration: none;
  background: #FF0000;
  padding: 1rem;
  font-size: 20px;
`

const Content = styled.div`
  max-width: 1200px;
  margin: 3rem auto 0 auto;
  display: flex;
  flex-direction: row-reverse;
  scroll-behavior: smooth;
  p {
    word-break: normal;
    hyphens: auto;
    margin-bottom: 1.45em;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const Parlament = styled.div`
  padding: 0 1rem;
  position: relative;
  width: 66%;
  margin-left: auto;
  h2:first-of-type {
    display: inline-block;
  }
  h2:last-of-type {
    float: right;
    display: inline-block;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    h2:first-of-type {
      display: inherit;
    }
    h2:last-of-type {
      float: none;
      display: inherit;
    }
  }
`

const Kopf = styled(Parlament)``
const Info = styled.div`
  padding: 0 1rem 0 1rem;
  align-self: flex-end;

  color: red;
  width: 33%;
  background: transparent;
  h2 {
    margin-top: 48px;
    margin-bottom: 5.07em;
    font-size: 2em;
    line-height: 1.45em;
    background: transparent;
  }
  @media only screen and (max-width: 1200px) {
    h2 {
      margin-bottom: 0.62em;
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    transform: translateY(0);
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const data = this.props.data.prismic.allEventseriess.edges[0].node

    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }

    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY 2020 IM PARLAMENT"
          description="Die GLOBART Academy Triade LEBEN-MACHT-SINN ist zurück! Die letzten Monate sind eine internationale Bestätigung: nun ist es Zeit über MACHT zu reden!"
          keywords={[
            "GLOBART Academy",
            "AKW Zwentendorf",
            "Philosophie",
            "Kunst",
            "Michaela Krömer",
            "Jakob Brossmann",
            "Michael Kerbler",
            "Dorothee Hartinger",
            "Ramona Kordesch",
            "Tanz die Toleranz",
            "Michael Mautner",
            "Stephan A.Jansen",
            "Friedrich von Borries",
            "Kultur",
          ]}
        />
        <RueckblickHeader>
          This was the GLOBART Academy in the Parliament
        </RueckblickHeader>

        <SwiperContainer>
         <StyledSwiper slider={data.slider} />
        </SwiperContainer>

        <FotoLinkContainer>
        <FotoLink to="https://www.flickr.com/photos/globart/albums/72157720172577580/page1">Images on Flickr → </FotoLink>
        
        <FotoLink to="https://www.okto.tv/de/oktothek/episode/6182a09c3189e">Videos on Okto → </FotoLink>
      
        <FotoLink to="https://www.parlament.gv.at/MEDIA/play.shtml?GP=XXVII&INR=60&ITYP=VER&INR_TEIL=1">Media Library of the parliament → </FotoLink>
        </FotoLinkContainer>

        <Content id="top">
          <Parlament id="akw">{RichText.render(data.body)}</Parlament>
          <Info>
            <h2>
              28th of October 2021 <br />
              <br />
              AUSTRIAN PARLIAMENT
            </h2>
          </Info>
        </Content>
        <Program events={data.events} />
        <Speakerlist en={true} heading="Speakers" speakers={data.speakers} />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query ParlamentIndexEnQuery {
    prismic {
      allEventseriess(lang: "en-gb", id: "Xv3qGBUAACoAeoRI") {
        edges {
          node {
            slider {
              image
              slidebody
            }
            body
            speakers {
              speaker {
                ... on PRISMIC_Speaker {
                  title
                  description
                  thumbnail
                  quote
                  _meta {
                    uid
                    id
                  }
                }
              }
            }
            events {
              event {
                ... on PRISMIC_Event {
                  title
                  subtitle
                  end_time
                  start_time
                  formatdescription
                  description
                  allday
                  musik
                  thumbnail
                  moderation {
                    ... on PRISMIC_Speaker {
                      title
                      excerpt
                      role
                      angefragt
                      _meta {
                        uid
                      }
                    }
                  }
                  speaker {
                    ... on PRISMIC_Speaker {
                      title
                      excerpt
                      role
                      angefragt
                      _meta {
                        uid
                      }
                    }
                  }
                  musicians {
                    musiker {
                      ... on PRISMIC_Speaker {
                        title
                        excerpt
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  speakers {
                    additional_speakers {
                      ... on PRISMIC_Speaker {
                        title
                        excerpt
                        role
                        angefragt
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                  _meta {
                    uid
                    lang
                  }
                  location
                  parallel
                  eventlocation
                }
              }
            }
          }
        }
      }
    }
  }
`
